<template>
  <div class="platformOperation">
    <div class="filter-box">
      <el-dropdown>
        <span class="el-dropdown-link">
          查询时间：{{ filterObj.type | setName(timeArr)
          }}<i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in timeArr"
            :key="index"
            @click.native="checkItem(item)"
            >{{ item.label }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="platform-box">
      <div class="title">
        平台基本情况
        <el-button type="text" @click="getNewView(1)">
          刷新
          <img src="@/assets/dashborad/icon-refresh.png" alt="" />
          <img class="on" src="@/assets/dashborad/icon-refresh.png" alt="" />
        </el-button>
      </div>
      <!-- 考试情况 start -->
      <div class="sub-tile">
        考试情况
        <el-button type="text" style="color: #3e4551" @click="linkTo()">
          查看更多考试
          <img src="@/assets/dashborad/icon-right.png" alt="" />
          <img class="on" src="@/assets/dashborad/icon-right.png" alt="" />
        </el-button>
      </div>
      <template v-if="examObj.list.length > 0 || listLoading">
        <div v-if="examObj.description" class="tips-msg">
          <div v-html="examObj.description"></div>
        </div>
        <examMsgTab ref="examMsg"></examMsgTab>
      </template>
      <template v-else>
        <noData>
          <div slot="message" style="text-align: center">
            近期暂无学科测验，去
            <el-button type="text" @click="linkTo()"> 创建考试 </el-button>
          </div>
        </noData>
      </template>
      <!-- 考试情况 end -->
      <hr />
      <!-- 各学科考试详情 start -->
      <div class="sub-tile">各学科考试详情</div>
      <div class="sroll-box">
        <img
          class="left-img"
          src="@/assets/dashborad/icon-left.png"
          alt=""
          @click="checkSroll(0)"
        />
        <div ref="subjuectRef" class="check-item-box edit-scroll-x-style">
          <div
            v-for="(item, index) in subjectOption"
            :key="index"
            :class="{ on: item.value == subjuectId }"
            @click="checkSubject(item)"
          >
            {{ item.label }}
          </div>
        </div>
        <img
          class="right-img"
          src="@/assets/dashborad/icon-right.png"
          alt=""
          @click="checkSroll(1)"
        />
      </div>
      <template v-if="subjectObj.list.length > 0 || subListLoading">
        <div v-if="subjectObj.description" class="tips-msg">
          <div v-html="subjectObj.description"></div>
        </div>
        <examMsgTab ref="subjectMsg"></examMsgTab>
      </template>
      <template v-else>
        <noData>
          <div slot="message" style="text-align: center">
            <div>近期暂无各学科考试数据</div>
          </div>
        </noData>
      </template>
      <!-- 各学科考试详情 end -->
      <hr />
      <!-- 各年级考试详情 start -->
      <div class="sub-tile">各年级考试详情</div>
      <div class="check-item-box">
        <div
          v-for="(item, index) in levelOptions"
          :key="index"
          :class="{ on: item.value == levelId }"
          @click="checkLevel(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <template v-if="gradeObj.list.length > 0 || gradeListLoading">
        <examMsgTab ref="gradeMsg"></examMsgTab>
      </template>
      <template v-else>
        <noData>
          <div slot="message" style="text-align: center">
            <div>近期暂无各年级考试数据</div>
          </div>
        </noData>
      </template>
      <!-- 各年级考试详情 end -->
      <hr />
      <!-- 平台各模块使用频次-教师 start-->
      <moduleContents
        ref="moduleContents"
        :teacher-role-id="0"
      ></moduleContents>
      <!-- 平台各模块使用频次-教师 end-->
      <hr />
      <!-- 家长端App关注度 start-->
      <APPattention ref="APPattention" :teacher-role-id="0"></APPattention>
      <!-- 家长端App关注度 end-->
    </div>
  </div>
</template>
<script>
const timeArr = [
  {
    label: "近一个月",
    value: 1,
  },
  {
    label: "近三个月",
    value: 2,
  },
  {
    label: "近半年",
    value: 3,
  },
  {
    label: "近一年",
    value: 4,
  },
];
import { getSubjectList } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
import examMsgTab from "./examMsgTab.vue";
import moduleContents from "@/views/dashboard/components/moduleContents.vue";
import APPattention from "@/views/dashboard/components/APPattention.vue";
import { examPreview } from "@/core/api/dashboard/index";
export default {
  name: "PlatformOperation",
  components: { examMsgTab, moduleContents, APPattention },
  data() {
    return {
      timeArr: timeArr,
      subjectOption: getSubjectList(),
      levelOptions: levelOptions(),
      tableData: [],
      subjuectId: "",
      searchType: 1,
      listLoading: false,
      subListLoading: false,
      gradeListLoading: false,
      levelId: 2,
      chartType: true,
      examObj: {
        list: [],
      },
      subjectObj: {
        list: [],
      },
      gradeObj: {
        list: [],
      },
      levelArr: [],
      filterObj: {
        type: 1,
      },
    };
  },
  created() {
    this.levelArr = this.$parent.levelArr;
    this.subjuectId = this.subjectOption[0].value;
    this.levelId = this.levelOptions[0].value;
    this.getNewView();
  },
  mounted() {
    this.$refs.APPattention.init();
    this.$refs.moduleContents.init();
  },
  methods: {
    getNewView(type) {
      this.getExamPreview();
      this.getSubjectPreview();
      this.getGradePreview();
      if (type) {
        this.$refs.APPattention.init();
        this.$refs.moduleContents.init();
      }
    },
    // 考试情况
    getExamPreview() {
      this.listLoading = true;
      examPreview(this.filterObj)
        .then((res) => {
          this.listLoading = false;
          this.examObj = res.data.data;
          this.$refs.examMsg.init(this.examObj.list, 1);
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    checkSubject(item) {
      if (this.subListLoading) return;
      this.subjuectId = item.value;
      this.getSubjectPreview();
    },
    // 各学科考试详情
    getSubjectPreview() {
      this.subListLoading = true;
      let data = Object.assign({ subjectId: this.subjuectId }, this.filterObj);
      examPreview(data)
        .then((res) => {
          this.subListLoading = false;
          this.subjectObj = res.data.data;
          this.$refs.subjectMsg.init(this.subjectObj.list, 2);
        })
        .catch(() => {
          this.subListLoading = false;
        });
    },
    checkLevel(item) {
      if (this.gradeListLoading) return;
      this.levelId = item.value;
      this.getGradePreview();
    },
    // 各年级考试详情
    getGradePreview() {
      this.gradeListLoading = true;
      let data = Object.assign({ level: this.levelId }, this.filterObj);
      examPreview(data)
        .then((res) => {
          this.gradeListLoading = false;
          this.gradeObj = res.data.data;
          this.$refs.gradeMsg.init(this.gradeObj.list, 3);
        })
        .catch(() => {
          this.gradeListLoading = false;
        });
    },
    linkTo() {
      this.$router.push({
        path: "/exam/exam",
      });
    },
    checkItem(item) {
      this.filterObj.type = item.value;
      this.getNewView(1);
    },
    checkSroll(type) {
      if (type) {
        this.$refs.subjuectRef.scrollLeft =
          this.$refs.subjuectRef.scrollLeft + 150;
      } else {
        this.$refs.subjuectRef.scrollLeft =
          this.$refs.subjuectRef.scrollLeft - 150;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.platformOperation {
  .sroll-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      flex-shrink: 0;
      width: 16px;
      margin-bottom: 20px;
      cursor: pointer;
    }
    .left-img {
      margin-right: 12px;
    }
    .right-img {
      margin-left: 12px;
    }
  }

  hr {
    // height: 1px;
    // background-color: #e9e9e9;
    border: none;
    border-bottom: 1px dashed #e9e9e9;
    margin-bottom: 18px;
  }
  .platform-box {
    background-color: #ffffff;
    padding: 18px;
  }
  .filter-box {
    background-color: #ffffff;
    padding: 18px;
    display: flex;
    justify-content: flex-end;
    margin: 18px 0;
    .el-dropdown-link {
      cursor: pointer;
      color: #0a1119;
    }
  }
  .title {
    font-size: 18px;
    color: #0a1119;
    font-weight: bold;
    margin-bottom: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
