<template>
  <div class="basicInformation">
    <div class="title">平台基本情况</div>
    <div class="sub-tile">教师数量</div>
    <!-- <template v-if="false"> -->
    <template v-if="teacherArr.length > 0 || $parent.viewLoading">
      <div class="tips-msg"><div v-html="teacherText"></div></div>
      <teacherChart ref="teacherChart"></teacherChart>
      <el-table
        v-if="teacherArr.length > 0 && subjectList.length > 0"
        :data="teacherArr"
        border
        style="width: 100%"
      >
        <el-table-column
          fixed
          prop="label"
          label="学段"
          align="center"
          width="150"
        >
          <template slot-scope="{ row }">
            {{ row.level | setName(levelArr) }}
          </template>
        </el-table-column>
        <template v-for="(item, index) in subjectList">
          <el-table-column
            :key="index"
            align="center"
            :label="item.subjectName"
            min-width="120px"
          >
            <template slot-scope="{ row }">
              {{ row[item.subjectId] }}
            </template>
          </el-table-column>
        </template>
      </el-table>
      <div class="tips-msg">
        注意：1、由于部分教师存在教授多个学科、学段情况，故教师统计数量与系统实际存在的账号数量可能会存在一定差异；2、其他：未有相关学段、学科权限账号数量；
      </div>
    </template>
    <template v-else>
      <noData>
        <div slot="message">
          暂无教师账号，去<el-button type="text" @click="goLink(1)">
            导入教师
          </el-button>
        </div>
      </noData>
    </template>
    <div class="sub-tile">学生数量</div>
    <template v-if="studentList.length > 0 || $parent.viewLoading">
      <div class="tips-msg">
        <div v-html="studentText"></div>
      </div>
      <div class="student-box">
        <studentChart
          v-for="(item, index) in studentList"
          :ref="'studentChart' + index"
          :key="index"
        ></studentChart>
      </div>
    </template>
    <template v-else>
      <noData>
        <div slot="message">
          暂无学生账号，去<el-button type="text" @click="goLink(2)">
            导入学生
          </el-button>
        </div>
      </noData>
    </template>
  </div>
</template>
<script>
import teacherChart from "./teacherChart.vue";
import studentChart from "./studentChart.vue";
import { levelOptions } from "@/core/util/constdata";
import { getSubjectList } from "@/core/util/util";
export default {
  name: "BasicInformation",
  components: { teacherChart, studentChart },
  props: {
    teacherRoleId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showList: true,
      levelItem: [],
      levelArr: [],
      studentList: [],
      teacherArr: [],
      subjectList: [],
      subArr: [],
      levelOptions: levelOptions(),
      subjectOption: getSubjectList(),
      teacherText: "",
      studentText: "",
    };
  },
  created() {
    this.levelArr = this.$parent.levelArr;
    // this.init();
  },
  methods: {
    init() {
      this.studentText = this.$parent.adminData.studentText;
      this.teacherText = this.$parent.adminData.teacherText;
      this.teacherVOList = this.$parent.adminData.teacherVOList;
      this.studentVOList = this.$parent.adminData.studentVOList;
      this.subjectList = this.teacherVOList[0].subjectList;
      if (this.teacherVOList && this.teacherVOList.length > 0) {
        this.teacherArr = this.teacherVOList.map((item) => {
          item.subjectList.map((index) => {
            item[index.subjectId] = index.number;
          });
          return item;
        });
      }

      // 重新过滤下
      if (this.studentVOList && this.studentVOList.length > 0) {
        this.studentList = [];
        let levelList = [];
        this.studentVOList.map((item) => {
          if (levelList.indexOf(item.level) == -1) {
            levelList.push(item.level);
            this.studentList.push({
              level: item.level,
              list: [item],
            });
          } else {
            this.studentList = this.studentList.map((i) => {
              if (i.level == item.level) {
                i.list.push(item);
              }
              return i;
            });
          }
        });
        this.$nextTick(() => {
          this.studentList.map((item, index) => {
            let str = "studentChart" + index;
            this.$refs[str][0].init(item);
          });
        });
      }

      this.$refs.teacherChart.init();
    },
    goLink(type) {
      let url = "";
      if (type == 1) {
        url = "/newOther/teacher";
      }
      if (type == 2) {
        url = "/newOther/student";
      }
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.basicInformation {
  background-color: #ffffff;
  padding: 18px;
  margin-top: 18px;
  line-height: 1;
  ::v-deep {
    .el-table th.el-table__cell > .cell {
      white-space: nowrap;
    }
  }
  .student-box {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .title {
    font-size: 18px;
    color: #0a1119;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .sub-tile {
    font-size: 16px;
    color: #0a1119;
    font-weight: 500;
    margin-bottom: 18px;
  }
}
</style>
