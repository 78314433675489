<template>
  <div class="teacherChart">
    <div id="TeacherChart" ref="TeacherChart" style="height: 243px"></div>
  </div>
</template>
<script>
export default {
  name: "TeacherChart",
  data() {
    return {};
  },
  created() {},
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      this.levelArr = this.$parent.levelArr;
      // console.log(JSON.parse(JSON.stringify(this.levelArr)));
      this.levelArr = this.levelArr.filter((item) => item.value != -1);
      // console.log(JSON.parse(JSON.stringify(this.levelArr)));
      let array = JSON.parse(JSON.stringify(this.$parent.teacherArr));
      array = array.filter((item) => item.level != -1);
      let dimensionsArr = ["product"];
      let seriesArr = [];

      let subjectArr = array[0].subjectList;

      array.map((item) => {
        this.levelArr.map((index) => {
          if (item.level == index.value) {
            dimensionsArr.push(index.label);
          }
        });
        seriesArr.push({
          type: "bar",
          barMaxWidth: "40px",
          barGap: "80%",
        });
      });
      subjectArr = subjectArr.map((item) => {
        return {
          subjectId: item.subjectId,
          product: item.subjectName,
        };
      });
      subjectArr = subjectArr.map((item) => {
        array.map((index) => {
          if (index.level == -1) return;
          let indexLevel = this.levelArr.filter(
            (i) => i.value == index.level
          )[0];

          if (index.level == indexLevel.value) {
            index.subjectList.map((key) => {
              if (key.subjectId == item.subjectId) {
                item[indexLevel.label] = key.number;
              }
            });
          }
        });
        return item;
      });

      const echarts = require("echarts");
      const chartDom = this.$refs.TeacherChart;
      const myChart = echarts.init(chartDom);

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          textStyle: { color: "#ffffff", fontWeight: "normal" },
          backgroundColor: "rgba(37,38,45,0.8);",
          borderColor: "rgba(37,38,45,0.8);",
        },
        dataset: {
          dimensions: dimensionsArr,
          source: subjectArr,
        },
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
        },
        legend: { itemGap: 30 },
        yAxis: {},
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: (5 / subjectArr.length) * 100,
          },
          {
            type: "slider",
          },
        ],
        color: [
          "#4D8DEFD9",
          "#73A7F6D9",
          "#96BFFCD9",
          "#B0CDFAD9",
          "#CDE1FFD9",
          "#CDE1FFD9",
        ],
        series: seriesArr,
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.teacherChart {
  margin-bottom: 18px;
}
</style>
