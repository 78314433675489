<template>
  <div class="nums-item">
    <span ref="number">0</span>
    <p>{{ keyName }}</p>
  </div>
</template>
<script>
export default {
  name: "NumsItem",
  props: {
    keyName: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {};
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.animateValue(0, this.value, 1000);
      },
    },
  },
  mounted() {
    if (!this.value) return;

    this.animateValue(0, this.value, 1000);
  },
  methods: {
    animateValue(start, end, duration) {
      // 显示数字的元素
      const numberElement = this.$refs.number;
      let startTimestamp = null;
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp;
        const progress = Math.min((timestamp - startTimestamp) / duration, 1);
        let number = Math.floor(progress * (end - start) + start);
        if (!numberElement) return;
        if (number > 100000) {
          numberElement.innerText = parseInt(number / 10000) + "W";
        } else {
          numberElement.innerText = number;
        }
        if (progress < 1) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    },
  },
};
</script>
<style lang="scss" scoped>
.nums-item {
  min-width: 70px;
  span {
    font-size: 24px;
    color: #0a1119;
    line-height: 24px;
  }
  p {
    margin-bottom: 0;
    margin-top: 10px;
    color: #3e4551;
    font-size: 12px;
  }
}
</style>
