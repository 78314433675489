<!-- 校领导 5 年级组长 4 备课组长 3 班主任 2 任课教师 1     0 代指教务 -->
<template>
  <div class="dashboardAdmin">
    <div class="dashboard-left">
      <headerCnt ref="headerCnt"></headerCnt>
      <basicInformation ref="basicInformation"></basicInformation>
      <platformOperation ref="platformOperation"></platformOperation>
    </div>
    <information
      ref="information"
      :teacher-role-id="teacherRoleId"
    ></information>
  </div>
</template>
<script>
const levelArr = [
  {
    value: -1,
    label: "全部",
  },
  {
    value: 0,
    label: "其他",
  },
  {
    value: 1,
    label: "小学",
  },
  {
    value: 2,
    label: "初中",
  },
  {
    value: 3,
    label: "高中",
  },
  {
    value: 4,
    label: "大学",
  },
];
import information from "../components/information.vue";
import headerCnt from "./components/headerCnt.vue";
import basicInformation from "./components/basicInformation.vue";
import platformOperation from "./components/platformOperation.vue";
import { basicStatistics } from "@/core/api/dashboard/index";
export default {
  name: "DashboardAdmin",
  components: {
    information,
    headerCnt,
    basicInformation,
    platformOperation,
  },
  data() {
    return {
      teacherRoleId: 0,
      adminData: {},
      levelArr: levelArr,
      flash: 0,
      viewLoading: false,
    };
  },
  created() {
    this.getbasicStatistics();
  },
  mounted() {
    this.$refs.information.init();
  },
  methods: {
    getbasicStatistics() {
      this.viewLoading = true;
      basicStatistics({ flash: this.flash })
        .then((res) => {
          // 修改原数据  全部
          res.data.data.teacherVOList = res.data.data.teacherVOList.map(
            (item) => {
              let nums = 0;
              item.subjectList.map((index) => {
                nums = nums + Number(index.number);
              });
              item.subjectList.push({
                number: nums,
                subjectId: -2,
                subjectName: "全部",
              });
              return item;
            }
          );
          this.adminData = res.data.data;
          this.flash = 0;
          this.$refs.headerCnt.init();
          this.$refs.basicInformation.init();
          this.viewLoading = false;
        })
        .catch(() => {
          this.viewLoading = false;
          this.$refs.headerCnt.btnLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboardAdmin {
  width: 1220px;
  display: flex;
  justify-content: space-between;
  margin: 18px auto;
  position: relative;

  .dashboard-left {
    width: 861px;
    margin-bottom: 24px;
  }
  ::v-deep {
    .el-button--text {
      font-weight: 400;
    }
    .edit-scroll-style {
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .edit-scroll-x-style {
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .more-box {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;

      i {
        transition: 0.2s linear;
        transform: rotate(270deg);
      }
    }
    .more-box-on {
      i {
        transform: rotate(90deg);
      }
    }
    .nums-tips {
      color: #2474ed;
    }
    .sroll-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        flex-shrink: 0;
        width: 16px;
        margin-bottom: 20px;
        cursor: pointer;
      }
      .left-img {
        margin-right: 12px;
      }
      .right-img {
        margin-left: 12px;
      }
    }
    .check-item-box {
      display: flex;
      scroll-behavior: smooth;
      margin-bottom: 18px;
      div {
        white-space: nowrap;
        line-height: 14px;
        padding: 6px 10px;
        margin-right: 8px;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 2px;
        &:hover {
          background: #eff5ff;
          color: #2474ed;
        }
      }
      .on {
        background: #eff5ff;
        color: #2474ed;
      }
    }
    .sub-tile {
      font-size: 16px;
      color: #0a1119;
      font-weight: 500;
      margin-bottom: 18px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .tips-msg {
      background: #f7faff;
      border-radius: 4px;
      padding: 10px;
      color: #3e4551;
      margin-bottom: 18px;
      line-height: 1.5;
      text-align: justify;
    }
    .el-table {
      margin-bottom: 18px;
      .el-table__cell {
        .cell {
          padding: 0 5px;
        }
      }
    }
    .el-table__fixed {
      height: 100% !important;
    }
    .el-table--scrollable-x .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }

      ///*<!--修改 滑块 -->*/
      // border: 1px solid #DEE1E7;
      &::-webkit-scrollbar-thumb {
        background: #d5d7da;
        border-radius: 10px;
      }
    }
  }
}
</style>
