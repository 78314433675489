<template>
  <div class="studentChart">
    <div
      id="studentChart"
      ref="studentChart"
      style="width: 100%; height: 160px"
    ></div>
  </div>
</template>
<script>
export default {
  name: "StudentChart",
  props: {},
  data() {
    return {
      studentObj: {},
      dataList: [],
    };
  },
  created() {},
  mounted() {
    // this.init();
  },
  methods: {
    init(item) {
      this.studentObj = item;
      let str = this.$parent.levelArr.filter(
        (item) => item.value == this.studentObj.level
      )[0].label;

      let number = 0;
      this.dataList = this.studentObj.list.map((item) => {
        item.value = item.number;
        item.name = item.year + "级";
        number = number + Number(item.number);
        return item;
      });
      const echarts = require("echarts");
      const chartDom = this.$refs.studentChart;
      const myChart = echarts.init(chartDom);

      const option = {
        title: {
          text: number,
          subtext: str + "学生总数",
          left: "29%",
          top: "40%",
          textAlign: "center",
        },
        tooltip: {
          trigger: "item",
          textStyle: { color: "#ffffff", fontWeight: "normal" },
          backgroundColor: "rgba(37,38,45,0.8);",
          borderColor: "rgba(37,38,45,0.8);",
        },
        legend: {
          orient: "vertical",
          right: "10",
          top: "center",
          icon: "circle",
          itemHeight: 8,
          data: this.dataList,
          formatter: (name) => {
            let total = 0;
            let target;
            const value = this.dataList.filter((x) => x.name == name)[0].value;
            for (let i = 0, l = this.dataList.length; i < l; i++) {
              total += this.dataList[i].value;
              if (this.dataList[i].name == name) {
                target = this.dataList[i].value;
              }
            }
            const arr = [
              "{name|" +
                name +
                "}{value|" +
                value +
                "}{percentage|" +
                ((target / total) * 100).toFixed(2) +
                "%}",
            ];
            return arr.join("\n");
          },
          textStyle: {
            rich: {
              name: {
                fontSize: 12,
                padding: [0, 0, 0, 10],
                color: "#0A1119",
              },
              value: {
                fontSize: 12,
                padding: [10, 0, 10, 10],
                color: "#83878F",
              },
              percentage: {
                fontSize: 12,
                padding: [10, 0, 10, 10],
                color: "#83878F",
              },
            },
          },
        },

        series: [
          {
            type: "pie",
            radius: ["70%", "95%"],
            center: ["30%", "50%"],
            label: {
              show: false,
              position: "center",
            },
            labelLine: {
              show: false,
            },
            color: [
              "#659FF7",
              "#FFDE84",
              "#EE8689",
              "#9CF2F1",
              "#FFBB74",
              "#A2CCF3",
            ],
            data: this.dataList,
          },
        ],
      };
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
.studentChart {
  width: 50%;
}
</style>
