<template>
  <div class="headerCnt">
    <div class="header-time">
      <img src="@/assets/dashborad/icon-welcome.png" alt="" />
      <span>欢迎使用，教务工作台！</span>
      上次使用时间：{{ getTime() }}
    </div>
    <div class="ref-time">
      时间截止：{{ indexTime }}

      <el-button type="text" style="margin-left: 10px" @click="getNewData()">
        刷新
        <img src="@/assets/dashborad/icon-refresh.png" alt="" />
        <img class="on" src="@/assets/dashborad/icon-refresh.png" alt="" />
      </el-button>
    </div>
    <div class="nums-box">
      <statisticsNums
        key-name="教师用户数量"
        :value="educationalBasicStatVO.teacherNum"
      ></statisticsNums>
      <statisticsNums
        key-name="学生用户数量"
        :value="educationalBasicStatVO.studentNum"
      ></statisticsNums>
      <statisticsNums
        key-name="答题卡模版数量"
        :value="educationalBasicStatVO.sheetNum"
      ></statisticsNums>
      <statisticsNums
        key-name="考试数量"
        :value="educationalBasicStatVO.examNum"
      ></statisticsNums>
      <statisticsNums
        key-name="答题卡扫描数量"
        :value="educationalBasicStatVO.scanNum"
      ></statisticsNums>
      <statisticsNums
        key-name="教师人均阅卷量"
        :value="educationalBasicStatVO.markNum"
      >
      </statisticsNums>
    </div>
  </div>
</template>
<script>
import statisticsNums from "./statisticsNums";
import moment from "moment";
import { getStore } from "@/core/util/store";
export default {
  name: "HeaderCnt",
  components: {
    statisticsNums,
  },
  data() {
    return {
      educationalBasicStatVO: {},
      indexTime: "",
      btnLoading: false,
    };
  },
  created() {},
  methods: {
    getTime() {
      let time = "";
      let { lastLoginTime } = getStore({ name: "userInfo" });
      if (!lastLoginTime) {
        return "-";
      }
      time = moment(new Date(lastLoginTime).getTime()).format(
        "YYYY年MM月DD日  HH:mm:ss "
      );
      return time;
    },
    init() {
      this.educationalBasicStatVO =
        this.$parent.adminData.educationalBasicStatVO;
      this.indexTime = moment().format("YYYY年MM月DD日  hh:mm:ss ");
      this.btnLoading = false;
    },
    getNewData() {
      this.$parent.flash = 1;
      this.$parent.getbasicStatistics();
    },
  },
};
</script>
<style lang="scss" scoped>
.headerCnt {
  background-color: #ffffff;
  padding: 18px;

  .nums-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  .ref-time {
    display: flex;
    justify-content: flex-end;
    margin: 18px 0;
    color: #83878f;
  }
  .header-time {
    padding: 12px;
    background: #f7faff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    span {
      font-weight: bold;
    }
  }
}
</style>
