<template>
  <div class="examMsgTab">
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column
        v-if="indexType != 3"
        prop="date"
        align="center"
        label="学段"
        width="60"
      >
        <template slot-scope="{ row }">
          {{ row.level | setName(levelArr) }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="indexType == 3"
        prop="date"
        align="center"
        label="年级"
        width="80"
      >
        <template slot-scope="{ row }">
          {{ row.year == "-1" ? "全部" : row.year + "级" }}
        </template>
      </el-table-column>
      <el-table-column prop="examNum" align="center" label="考试量" width="80">
        <template slot-scope="{ row }">
          {{ row.examNum ? row.examNum : "0" }}
        </template>
      </el-table-column>
      <el-table-column prop="examInterval" align="center" label="考试间隔">
        <template slot-scope="{ row }">
          {{ row.examInterval ? row.examInterval : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="examIngNum"
        align="center"
        width="116"
        label="进行中的考试"
      >
        <template slot-scope="{ row }">
          {{ row.examIngNum ? row.examIngNum : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="examDate"
        align="center"
        label="最近考试时间"
        width="116"
      >
        <template slot-scope="{ row }">
          {{ row.examDate ? row.examDate : "-" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="scanNum"
        align="center"
        label="答题卡扫描量"
        width="116"
      >
        <template slot-scope="{ row }">
          {{ row.scanNum ? row.scanNum : 0 }}
        </template>
      </el-table-column>
      <el-table-column prop="teacherNum" align="center" label="阅卷教师">
        <template slot-scope="{ row }">
          {{ row.teacherNum ? row.teacherNum : 0 }}
        </template>
      </el-table-column>
      <el-table-column prop="markNum" align="center" label="人均阅卷量">
        <template slot-scope="{ row }">
          {{ row.markNum ? row.markNum : 0 }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "ExamMsgTab",
  data() {
    return {
      tableData: [],
      levelArr: [],
      indexType: 1,
    };
  },
  created() {
    this.levelArr = this.$parent.levelArr;
  },
  methods: {
    init(list, type) {
      this.tableData = list;
      this.indexType = type;
      // console.log(this.tableData);
    },
  },
};
</script>
<style lang="scss" scoped>
.examMsgTab {
}
</style>
